// React imports
import { createContext, useContext, useReducer } from 'react';

// Generic API helper
import ApiHelper from 'helpers/ApiHelper';

// Constant API names and default api params
import { API_NAMES } from 'constants/ApiConstants';

// Reducer for changing state
import reducer from 'reducers/master/academic/divisionReducer';
import { useDefaultFilterContext } from 'contexts/defaultFilterContext';

// context to be used in the app
const DivisionContext = createContext();

// hook to use the context states in the ui
const useDivisionContext = () => {
  return useContext(DivisionContext);
};
export { useDivisionContext };

// Initial State
const initialState = {
  data: [],
  count: 0,
};

// provider of the context
const DivisionProvider = ({ children }) => {
  // API helper functions to hit APi's
  const { GetListAPI, GetAPI, UpdateAPI, CreateAPI, DeleteAPI } = ApiHelper();

  // Reducer to set state
  const [state, dispatch] = useReducer(reducer, initialState);
  const { defaultFiltersMemo } = useDefaultFilterContext();

  // Name of the api to hit
  const apiName = API_NAMES.MASTER.ACADEMIC.DIVISION;

  // Fetches whole records from DB
  const getList = (params, callbackFunction, standard_id) => {
    if (standard_id && !params?.academic_year_id) {
      params = {
        ...(params || {}),
        academic_year_id: defaultFiltersMemo?.academic_year_id,
      };
    }
    return GetListAPI(
      standard_id
        ? `divisionstandardbranchacademicyearmappingsbystandard/${standard_id}`
        : apiName,
      dispatch,
      params,
      callbackFunction
    );
  };

  // Fetches single record in records of DB
  const getItem = (_id) => {
    GetAPI(apiName, dispatch, _id);
  };

  // Update single record in records of DB
  const updateItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    UpdateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  };

  // Add new record in records of DB
  const createItem = (formData, setSubmitting, resetForm, handleAddClose) => {
    CreateAPI(
      apiName,
      dispatch,
      formData,
      setSubmitting,
      resetForm,
      handleAddClose
    );
  };

  // Delete on or may records from records in DB
  const deleteItems = (params, _ids) => {
    DeleteAPI(apiName, dispatch, params, _ids);
  };

  return (
    <DivisionContext.Provider
      value={{
        data: state.data,
        count: state.count,
        getList,
        getItem,
        updateItem,
        createItem,
        deleteItems,
      }}
    >
      {children}
    </DivisionContext.Provider>
  );
};

export default DivisionProvider;
